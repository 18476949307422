let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    {
        pageFlag: false,
        id: "dgcommon",
        title: "日常业务",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "orderclass",
          title: "往来单位分类同步",
          name: "orderclass",
          icon: "el-icon-document",
          component: "views/dg/AAOrder.vue"
          ,visible:true
        },
        {
          pageFlag: true,
            id: "axgdcomment",
            title: "资料同步信息",
            name: "axgdcomment",
            icon: "el-icon-document",
            component: "views/zb/AxGdCommentList.vue",
            visible: true
        }
      ]
      }
]
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}
//other
__system = {
  __activeid: "",
  __menu: [
    // {
    //   pageFlag: true,
    //   id: "test",
    //   title: "test",
    //   name: "test",
    //   icon: "el-icon-document",
    //   component: "views/syc/Test.vue"
    //   ,visible:true
    // },
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    {
      pageFlag: false,
        id: "dgcommon",
        title: "日常业务",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "orderclass",
          title: "采购单同步普通订单",
          name: "orderclass",
          icon: "el-icon-document",
          component: "views/dg/AAOrder.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "orderclassgund",
          title: "采购单同步滚动订单",
          name: "orderclassgund",
          icon: "el-icon-document",
          component: "views/dg/AAOrdergund.vue"
          ,visible:true
        },
        {
          pageFlag: true,
            id: "axgdcomment",
            title: "资料同步信息",
            name: "axgdcomment",
            icon: "el-icon-document",
            component: "views/dg/AxGdCommentList.vue",
            visible: true
        }
      ]
    }
],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld